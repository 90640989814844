//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ScrollSequence',
    props: ['data'],
    data() {
        return {
            totalFrames: 1,
            element: null,
            canvas: null,
            imageUrl: null,
            contentElement: null,
            currentFrameNum: 1,
            texts: [],
            scrollFactor: 0.2,
        };
    },
    computed: {
        scrollSequenceInnerHeight() {
            const factor = typeof this.data !== 'undefined' && typeof this.data.scrollFactor !== 'undefined' ? this.data.scrollFactor : 5;
            return this.totalFrames * (typeof window !== 'undefined' ? window.innerHeight / parseInt(factor) : 1) + 'px';
        },
    },
    methods: {
        updateCanvasSize() {
            this.canvas.width = document.documentElement.clientWidth;
            this.canvas.height = document.documentElement.clientHeight;
            this.canvas.style.width = `${document.documentElement.clientWidth}px`;
            this.canvas.style.height = `${document.documentElement.clientHeight}px`;
        },
        start() {
            this.preloadImages();
            window.addEventListener('scroll', () =>
                requestAnimationFrame(() => {
                    this.getFrameRates(this.element);
                })
            );
            this.getFrameRates(this.element);
        },
        drawImage() {
            const ctx = this.canvas.getContext('2d');
            const type = 'cover';
            const img = this.currentImage;
            const imgRatio = img.height / img.width;
            const winRatio = window.innerHeight / window.innerWidth;
            if ((imgRatio < winRatio && type === 'contain') || (imgRatio > winRatio && type === 'cover')) {
                const h = window.innerWidth * imgRatio;
                ctx.drawImage(img, 0, (window.innerHeight - h) / 2, window.innerWidth, h);
            }
            if ((imgRatio > winRatio && type === 'contain') || (imgRatio < winRatio && type === 'cover')) {
                const w = (window.innerWidth * winRatio) / imgRatio;
                ctx.drawImage(img, (window.innerWidth - w) / 2, 0, w, window.innerHeight);
            }
        },
        currentFrame(frame) {
            return this.imageUrl + frame.toString() + '.jpg';
        },
        getFrameRates(element) {
            const rect = element.getBoundingClientRect();
            const positiveTop = rect.top <= 0 ? Math.abs(rect.top) : 0;
            const height = rect.height - window.innerHeight;
            const scrollProgress = Math.min(1, positiveTop / height);
            this.currentFrameNum = Math.round(scrollProgress * (this.totalFrames - 1)) + 1;
            this.currentImage = new Image();
            this.currentImage.src = this.currentFrame(this.currentFrameNum);
            this.currentImage.onload = () => {
                this.drawImage();
            };
        },
        preloadImages() {
            for (let i = 1; i <= this.totalFrames; i++) {
                const img = new Image();
                img.src = this.currentFrame(i);
            }
        },
    },
    mounted() {
        this.element = this.$refs.scrollSequence;
        this.contentElement = this.$refs.scrollSequenceContent;
        this.canvas = this.$refs.scrollSequenceCanvas;
        this.totalFrames = this.data.frameCount;
        let folder = 'desktop';
        if (window.innerWidth <= 768) {
            folder = 'mobile';
        } else if (window.innerWidth <= 1024) {
            folder = 'tablet';
        }
        this.imageUrl = this.$config.pimcoreUrl + '/' + this.data.assetPath + '/' + folder + '/';
        this.texts = this.data.texts.map((item) => {
            return {
                ...item,
                startFrame: parseInt(item.startFrame),
                endFrame: parseInt(item.endFrame),
            };
        });
        window.addEventListener('resize', () => {
            this.updateCanvasSize();
            this.getFrameRates(this.element);
        });
        this.updateCanvasSize();
        this.start();
    },
};
